import { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Router } from './business/constants/router';
import { LayoutHeader } from '@pages/layout/header-layout';
import React from 'react';
import { Button, Modal } from 'antd';
import './app.less';
import logo from '@assets/img/logos.jpg';
import QRCode from '@assets/img/QRCode.png';

const Home = lazy(()=>import(/* webpackChunkName:'home' */ '@pages/home'))
const Contact = lazy(()=>import(/* webpackChunkName:'Contact' */ '@pages/contact'))
const Recruitment = lazy(()=>import(/* webpackChunkName:'Recruitment' */ '@pages/recruitment'))
const About = lazy(()=>import(/* webpackChunkName:'About' */ '@pages/about'))
const NoticeContent = lazy(()=>import(/* webpackChunkName:'NoticeContent' */ '@pages/noticeContent'))

function App() {
  const [modalVisible, setModalVisible] = React.useState(true);

  const onOk = React.useCallback(()=>{
    setModalVisible(false);
  },[]) 

  const onCancel = React.useCallback(()=>{
  	window.location.replace("https://www.baidu.com/")
  },[]) 

  /**
   * 忽略异常
   */
 React.useEffect(() => {
  window.addEventListener('error', e => {
    if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      )
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      )
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  })
}, [])
const [position, setPosition] = React.useState({ x: 0, y: 0 });
React.useEffect(() => {
  const moveBox = () => {
    const newX = Math.random() * (window.innerWidth - 100);
    const newY = Math.random() * (window.innerHeight - 100);
    setPosition({ x: newX, y: newY });
  };

  const interval = setInterval(moveBox, 1000); // 每秒移动一次
  return () => clearInterval(interval);
}, []);
  return (
    <div className='project--content--wrap'>
      <div className='project--content'>
        <LayoutHeader/>
        <Switch>
          <Route exact path={Router.Home} ><Home/></Route>
          <Route exact path={Router.About} ><About/></Route>
          <Route exact path={Router.Contact} ><Contact/></Route>
          <Route exact path={Router.Recruitment} ><Recruitment/></Route>
          <Route exact path={Router.NoticeContent} ><NoticeContent/></Route>
          <Route exact path={'/'} ><Recruitment/></Route>
          <Redirect to="*" />
        </Switch>
        <Modal title="合格投资者声明" open={modalVisible} width={1000} onOk={onOk} onCancel={onCancel} closable={false} footer={[<Button key="back" onClick={onCancel} className='abandon'>放弃</Button>,<Button key="submit" type="primary"  onClick={onOk} className='accept'>接受</Button>,]}>
          <div className='title'>尊敬的投资者:</div>
          <div className='title_one'>在继续浏览本公司网站前，请您确认您或您所代表的机构是一名“合格投资者”。根据我国《私募投资基金监督管理暂行办法》的规定，合格投资者的标准如下：</div>
          <div className='title_one'>一、具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</div>
          <div className='title_two'>1、净资产不低于1000万元的单位；</div>
          <div className='title_two'>2、金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。(前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。)</div>
          <div className='title_one'>二、下列投资者视为合格投资者：</div>
          <div className='title_two'>1、社会保障基金、企业年金等养老基金、慈善基金等社会公益基金；</div>
          <div className='title_two'>2、依法设立并在基金业协会备案的投资计划；</div>
          <div className='title_two'>3、投资于所管理私募基金的私募基金管理人及其从业人员；</div>
          <div className='title_two'>4、中国证监会规定的其他投资者。</div>
        </Modal>
      </div >
      <div className='project--footer'>
        <img src={logo} alt="logo" style={{width:'50px', marginRight:'10px'}} />
        {/* 联系地址：北京市海淀区海淀北二街8号中关村SOHO1503<br/> */}
        Copyright © 北京道泰量合私募基金管理有限公司 Beijing Dao Tai Liang He Private Fund Management Co., Ltd
        <img src={QRCode} alt="QRCode" style={{width:'50px', marginLeft:'10px'}} />
      </div>
      {/* <div className='follower' style={{position: 'absolute',top: position.y,left: position.x,width: '300px',height: '150px',}}></div> */}
    </div>
    
    
  );
}

export default App;
