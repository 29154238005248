export enum UrlParams  {
  AboutMenu = 'aboutMenu', 
} 

export enum Router {
  Home = '/home',//公司公告
  About = '/about',//关于
  Contact = '/contact',//联系我们
  Recruitment = '/recruitment',//招聘
  NoticeContent = '/noticeContent', //公告内容
}